import React, { useState } from "react";
import { QRCodeSVG } from "qrcode.react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage() {
  const [status, setStatus] = useState("");

  const emailLink =
    "mailto:proff461444@mail.ru;lida.proff@mail.ru?subject=Обращение&body=Текст%20сообщения";
  // "mailto:proff461444@mail.ru?subject=Обращение&body=Текст%20сообщения";

  // const emailLink = "mailto:proff461444@mail.ru";

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  };

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Контакты"
      />
      <section>
        <h1 className="text-3xl mb-6 uppercase font-bold text-center  ">
          Контакты образовательной организации
        </h1>
        <div className="sm:flex">
          <div className="sm:w-1/2">
            <div className="bg-white p-4 mb-2 rounded shadow">
              <h3 className="text-xl mb-2 font-bold">Местонахождение:</h3>
              <p>
                628383, Ханты-Мансийский Автономный округ - Югра АО, Пыть-Ях г,
                6 Пионерный мкр, дом № 30
              </p>
            </div>
            <div className="bg-white p-4 mb-2 rounded shadow">
              <h3 className="text-xl mb-2 font-bold">Режим и график работы:</h3>
              <div>
                <ul>
                  <li>понедельник - пятница: с 09:00 до 20:00</li>
                  <li>суббота: с 09:00 до 16:00</li>
                  <li>воскресенье: выходной</li>
                </ul>
              </div>
            </div>
            <div className="bg-white p-4 mb-2 rounded shadow">
              <h3 className="text-xl mb-2 font-bold">
                Контактные телефоны и факс::
              </h3>
              <p>Для звонков +73463461444 Для SMS +79322575263</p>
            </div>
            <div className="bg-white p-4 rounded shadow">
              <h3 className="text-xl mb-2 font-bold">
                Адрес электронной почты::
              </h3>
              <p className="text-blue-700">proff461444@mail.ru</p>
              {/* Добавляем QR-код */}
              <div className="mt-4">
                <h4 className="text-lg mb-2">
                  Отсканируйте или кликните для отправки email:
                </h4>
                <a
                  href={emailLink}
                  target="_blank"
                  className="bg-white p-4 inline-block rounded-lg shadow hover:shadow-2xl hover:opacity-60"
                >
                  <QRCodeSVG
                    value={emailLink}
                    size={128}
                    level="Q"
                    // includeMargin
                    className="mx-auto"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="sm:w-1/2 p-4 bg-white shadow rounded sm:ml-2 mt-4 sm:mt-0">
            <h2 className="text-center text-xl font-bold mb-4">
              Обратная связь
            </h2>

            <form
              action="https://formspree.io/f/xdopyljv"
              method="POST"
              onSubmit={handleSubmit}
            >
              <div className="flex mb-6">
                <input
                  type="text"
                  name="name"
                  className="w-1/2 p-2 text-gray-900 shadow rounded bg-green-100"
                  placeholder="Ваше имя"
                />
                <input
                  type="text"
                  name="phone"
                  className="w-1/2 p-2 ml-2 text-gray-900 shadow rounded bg-green-100"
                  placeholder="Ваш телефон"
                />
              </div>
              <input
                type="email"
                name="email"
                className="p-2 w-full text-gray-900 shadow rounded bg-green-100 mb-6"
                placeholder="Ваш электронный адрес"
              />
              <input
                type="text"
                name="title"
                className="p-2 w-full text-gray-900 shadow rounded bg-green-100 mb-6"
                placeholder="Заголовок сообщения"
              />
              <textarea
                type="text"
                name="message"
                className="p-2 w-full  h-32 text-gray-900 shadow rounded bg-green-100 mb-6"
                placeholder="Текст сообщения"
              />
              <div className="flex ">
                <button
                  type="submit"
                  className="px-6 py-2 bg-teal-200 rounded hover:shadow hover:bg-teal-300"
                >
                  Отправить
                </button>
                {status === "SUCCESS" && (
                  <p className="text-green-500">
                    Ваше сообщение успешно отправлено!
                  </p>
                )}
                {status === "ERROR" && (
                  <p className="text-red-600">Возникла ошибка при отправке!</p>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
